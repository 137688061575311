<template>
  <Modal
    v-model="visible"
    fullscreen
    :closable="false"
    class-name="aisleJyModal"
  >
    <p slot="header">
      聚英卡【{{jyObj.name}}】的通道配置
      <Icon 
        title="返回设备列表" 
        type="ios-undo" 
        style="color: #08DDDD; position: absolute;top: 12px;right: 20px;font-size: 25px;cursor: pointer"
        @click="$emit('ajModalClose')"
      />
    </p>
    <div class = "filterBar flexboxb" style="margin-top: 0">
      <div class = "flexboxs">
        <div class = "filterItem">
          <Select class = "selectMid" :value="filter.doType" placeholder="选择通道类型" label-in-value @on-change="({value})=>{filerChange(value, 'doType', 'select')}">
            <Option v-for="(item, index) in doTypeOptions" :value="item.value" :key="index">{{ item.label }}</Option>
          </Select>
        </div>
        <div class = "filterItem">
          <Select class = "selectMid" :value="filter.status" placeholder="选择通道状态" label-in-value @on-change="({value})=>{filerChange(value, 'status', 'select')}" clearable @on-clear="statusClear">
            <Option :value="0">未绑定</Option>
            <Option :value="1">已绑定</Option>
          </Select>
        </div>
      </div>
      <div v-if="accessData.boardAisleAdd">
        <span class = "btnMy" @click="aisleNew">添加通道</span>
      </div>
    </div>
    <div class="jyAisleGrid">
      <Table :loading="listLoading" border :columns="listCol" :data="listData" :height="getGridHeight()"></Table>
    </div>
    <div class="pageBar">
        <Page :total="page.total" :current="page.pageNo" :page-size ="page.pageSize" show-elevator show-total @on-change="pageChange"></Page>
    </div>
    <div slot="footer" style="padding: 0">
      <!-- <Button type="primary" @click="$emit('ajModalClose')">返回板卡列表</Button> -->
    </div>
  </Modal>
</template>
<script>
export default {
  name: 'AisleJyModal',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    jyObj: {
      type: Object,
      default: function () {
        return {
          name: '',
          guid: '',
        }
      }
    }
  },
  data () {
    return {
      page: {
        total: 0,
        pageNo: 1,
        totalPage: 0,
        pageSize: 10,
      },
      filter: {
        doType: 0
      },
      doTypeOptions: [
        {
          value: 0,
          label: '以太网AI通道'
        },
        {
          value: 1,
          label: '以太网DO通道'
        },
      ],
      listData: [],
      listCol: [
        {
          title: '通道名称',
          key: 'channelName',
          minWidth: 100,
          render: (m, {row}) => {
            let c = this.$createElement;
            return row.isE && row.guid ? 
              c('Input', {
                attrs: {
                    // value: this.newData.name
                },
                'class': {
                    noPass: row.channelName ? false : true,
                },
                props: {
                    value: row.channelName,
                },
                ref: 'jyaislenameInput',
                on: {
                    'on-change': (e) => {
                      // row.name = e.target.value;
                      if (!row.guid) {
                        this.listData.splice(0, 1, {
                          ...this.listData[0],
                          channelName: e.target.value
                        });
                      } else {
                        for (var i = 0; i < this.listData.length; i++) {
                          this.listData[i].guid === row.guid && this.listData.splice(i, 1, {
                            ...this.listData[i],
                            channelName: e.target.value
                          })
                        }
                      }
                      this.$nextTick(() => {
                        this.$refs.jyaislenameInput.focus();
                      })
                    }
                }
              }) : row.isE && !row.guid ? 
              c('span', '--') :
              c('span', row.channelName)
          }
        },
        {
          title: '通道号',
          key: 'channelNo',
          minWidth: 100,
          render: (m, {row}) => {
            let c = this.$createElement;
            let minNum = c('div', {
              'class': {
                noPass: (row.beginNo || +row.beginNo === 0)
                  && (+row.beginNo <= +row.endNo) ? false : true,
              },
            }, [c('InputNumber', {
              attrs: {
                // value: this.newData.name
              },
              props: {
                value: row.beginNo,
                min: 0,
                step: 1,
              },
              ref: 'jyaislebeginNoInput',
              on: {
                'on-change': (val) => {
                  // row.name = e.target.value;
                  if (!row.guid) {
                    this.listData.splice(0, 1, {
                      ...this.listData[0],
                      beginNo: val
                    });
                  } else {
                    for (var i = 0; i < this.listData.length; i++) {
                      this.listData[i].guid === row.guid && this.listData.splice(i, 1, {
                        ...this.listData[i],
                        beginNo: val
                      })
                    }
                  }
                  this.$nextTick(() => {
                    this.$refs.jyaislebeginNoInput.$el.children[1].children[0].focus();
                  })
                }
              }
            })]);
            let maxNum = c('div', {
              'class': {
                noPass: (row.endNo || +row.endNo === 0)
                  && (+row.beginNo <= +row.endNo) ? false : true,
              },
            }, [c('InputNumber', {
              attrs: {
                // value: this.newData.name
              },
              props: {
                value: row.endNo,
                min: 0,
                step: 1,
              },
              ref: 'jyaisleendNoInput',
              on: {
                'on-change': (val) => {
                  // row.name = e.target.value;
                  if (!row.guid) {
                    this.listData.splice(0, 1, {
                      ...this.listData[0],
                      endNo: val
                    });
                  } else {
                    for (var i = 0; i < this.listData.length; i++) {
                      this.listData[i].guid === row.guid && this.listData.splice(i, 1, {
                        ...this.listData[i],
                        endNo: val
                      })
                    }
                  }
                  this.$nextTick(() => {
                    this.$refs.jyaisleendNoInput.$el.children[1].children[0].focus();
                  })
                }
              }
            })])
            return row.isE && !row.guid ? 
              c('div', {
                style: {
                  display: 'flex',
                  alignItems: 'center'
                }
              }, [minNum, c('span', {style: {margin: '0 5px'}}, '~'), maxNum]) : c('span', row.channelNo)
          }
        },
        {
          title: '通道类型',
          key: 'doType',
          minWidth: 100,
          render: (c, {row}) => {
            return c('span', this.filter.doType === 0 ? 'AI通道' : 'DO通道')
          }
        },
        {
          title: '通道状态',
          key: 'statusName',
          minWidth: 100,
          render: (c, {row}) => {
            return row.statusName ? c('span', row.statusName) : c('span', '--')
          }
        },
        {
          title: '备注',
          key: 'remark',
          minWidth: 100,
          render: (c, {row}) => {
            return !row.isE ? 
              c('span', row.remark) :
                c('Input', {
                  attrs: {
                    // value: this.newData.name
                  },
                  props: {
                    value: row.remark,
                  },
                  on: {
                    'on-change': (e) => {
                      row.remark = e.target.value
                    }
                  }
                })
          }
        },
        {
          title: '操作',
          key: 'action',
          width: 110,
          render: (c, {row}) => {
            if (row.isE) {
              return c('div', [
                c('Icon', {
                  attrs: {
                    title: '保存'
                  },
                  style: {
                    marginRight: '15px',
                    cursor: 'pointer',
                    color: '#08DDDD',
                    fontSize: '16px',
                  },
                  props: {
                    type: 'md-checkmark'
                  },
                  nativeOn: {
                    click: () => {
                      !row.guid ? this.saveAisleNew(row) : this.saveAisleEdit(row);
                    }
                  }
                }),
                c('Icon', {
                  attrs: {
                    title: '取消'
                  },
                  style: {
                    cursor: 'pointer',
                    color: '#08DDDD',
                    fontSize: '16px',
                  },
                  props: {
                    type: 'md-close'
                  },
                  nativeOn: {
                    click: () => {
                      !row.guid ? this.cancelAisleNew(row) : this.cancelAisleEdit(row)
                    }
                  }
                }),
              ])
            } else {
              let delBtn = c('Icon', {
                attrs: {
                  title: '删除'
                },
                style: {
                  cursor: 'pointer',
                  color: '#08DDDD',
                  fontSize: '16px',
                },
                props: {
                  type: 'md-trash'
                }
              });
              return this.deleteLoading && this.deletingId === row.guid ? c('Spin', {
                props: {
                  size: 'small'
                }
              }) : c('div', [
                this.boardAisleAdd.boardAisleMod && c('Icon', {
                  attrs: {
                    title: '编辑'
                  },
                  style: {
                    cursor: 'pointer',
                    color: '#08DDDD',
                    fontSize: '16px',
                    marginRight: '10px'
                  },
                  props: {
                    type: 'ios-brush'
                  },
                  nativeOn: {
                    click: () => {
                      this.jyAisleEdit(row)
                    }
                  }
                }),
                /* c('Icon', {
                  attrs: {
                    title: '通道绑定'
                  },
                  style: {
                    cursor: 'pointer',
                    color: '#08DDDD',
                    fontSize: '16px',
                    marginRight: '10px'
                  },
                  props: {
                    type: 'md-git-compare'
                  },
                  nativeOn: {
                    click: () => {
                      this.aisleSet(row)
                    }
                  }
                }), */
                this.boardAisleAdd.boardAisleDel && c('Poptip', {
                  props: {
                    confirm: true,
                    title: '确认删除？',
                    placement: 'left'
                  },
                  on: {
                    'on-ok': () => {
                      this.jyAisleDel(row.guid)
                    },
                    'on-cancel': () => {

                    }
                  }
                }, [delBtn])
              ])
            }
          }
        }
      ],
      listLoading: false,
      statusLoading: false,
      deleteLoading: false,
      editLoading: true,
      deletingId: '',
      statusId: '',
      editId: '',
    }
  },


  computed: {
    accessData () {
      return {
        boardAisleAdd: true, // this.$store.state.app.permissionsGroup.boardAisleAdd,
        boardAisleDel: true, // this.$store.state.app.permissionsGroup.boardAisleDel,
        boardAisleMod: true, // this.$store.state.app.permissionsGroup.boardAisleMod,
      }
    }
  },

  methods: {
    getGridHeight () {
      return window.innerHeight - 188
    },

    statusClear () {
      (this.filter.status || this.filter.status === 0) && delete this.filter.status;
      this.getList(true)
    },

    getList (loadingShow) {
      loadingShow && (this.listLoading = true);
      this.$post(this.$api.AISLE.JY_AISLELIST, {
        pageSize: this.page.pageSize,
        pageNo: this.page.pageNo,
        ...this.filter,
      }).then(resp => {
        this.listData = resp.list.map(item => {
          return {
            ...item,
            farmId: +item.farmId,
          }
        });
        this.page = {
          total: +resp.total,
          pageNo: +resp.pageNo,
          totalPage: +resp.totalPage,
          pageSize: +resp.pageSize,
        }
        loadingShow && (this.listLoading = false);
      }).catch(e => {
        loadingShow && (this.listLoading = false);
      })
    },

    filerChange (value, field, type) {
      if (type === 'select') {
        this.filter = {
          ...this.filter,
          [field]: value,
        };
        this.getList(true)
      }
    },

    pageChange (pageNo) {
      this.page.pageNo = pageNo
      this.getList(true)
    },

    aisleNew () {
      if (this.listData[0] && this.listData[0].isE) return;
      this.listData.unshift({
        isE: true,
        beginNo: 0,
        endNo: 0,
      })
    },

    saveAisleNew (record) {
      let noPass = !(record.beginNo || +record.beginNo === 0)
        || !(record.endNo || +record.endNo === 0)
          || !(+record.beginNo <= +record.endNo);
      if (noPass) return;
      this.listLoading = true;
      delete record.isE;
      this.$post(this.$api.AISLE.JY_AISLENEW, {
        ...record,
        jycardId: this.jyObj.guid,
        jycardName: this.jyObj.name,
        doType: this.filter.doType
      }).then(resp => {
        this.getList(true)
      }).catch(e => {
        this.listLoading = false;
      })

    },

    cancelAisleNew () {
      this.listData.shift()
    },

    jyAisleDel (guid) {
      this.deletingId = guid;
      this.deleteLoading = true;
      this.$post(this.$api.AISLE.JY_AISLEDEL, {
        guid
      }).then(resp => {
        this.deleteLoading = false;
        this.getList(true)
      }).catch(e => {
        this.deleteLoading = false;
      })

    },

    jyAisleEdit (record) {
      let listData = this.listData.map(item => {
        return item.guid === record.guid ? {...item, isE: true} : item
      })
      this.listData = listData;
      this.editData = {...record}
    },

    saveAisleEdit (record) {
      let noPass = !record.channelName;
      if (noPass) return;
      this.editLoading = true;
      this.editId = record.guid;
      this.$post(this.$api.AISLE.JY_AISLEEDIT, {
        channelName: record.channelName,
        guid: record.guid,
        remark: record.remark,
      }).then(resp => {
        this.editLoading = false;
        this.getList(true);
      }).catch(e => {
        this.editLoading = false;
      })

    },

    cancelAisleEdit (record) {
      this.listData = this.listData.map(item => {
        return item.guid === record.guid ? this.editData : item
      });
    },

  },

  mounted () {
    // this.getList(true)
  },

  watch: {
    jyObj: {
      handler (newVal, oldVal) {
        this.filter={
          ...this.filter,
          jycardId: newVal.guid
        }
        this.getList(true)
      },
      deep: true,
    }
  }
}
</script>
<style lang="less">
.aisleJyModal {
  .ivu-modal-footer {
    padding: 0;
  }
  .ivu-modal-body {
    @footer: 51px;
    height: calc(~"100vh - @{footer}");
  }
  .noPass {
    input {
      border: 1px solid #e4393c;
    }
    .ivu-select-selection {
      border: 1px solid #e4393c;
    }
    .ivu-input-number {
      border: 1px solid #e4393c;
      input {
        border: none;
      }
    }
  }
}
.jyAisleGrid {
  // margin-top: 15px;
}
</style>


